<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Technicians/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="col-12">
              <div v-if="!userSearchStatus" class="position-relative" style="min-height: 60vh">
                <div class="position-absolute " style="top: 50%;transform:translateY(-50%); left: 0;right: 0">
                  <h5 class="text-center mb-2">To create an Technician on the system, start by first inputting the specified email address or phone number.</h5>
                  <div class="row center-div mx-auto">
                    <div class="col-lg-10 col-sm-12 ">
                      <div class="has-icon-left position-relative">
                        <input type="text" id="userSearch" class="form-control" v-model="userSearchQuery" placeholder="Type full email address or phone number">
                        <div class="form-control-position"><i class='bx bx-search-alt'></i></div>
                      </div>
                      <div v-if="errors.existingRegisteredTechnician" class="text-danger">{{ errors.existingRegisteredTechnician }}</div>

                      <div v-if="registeredEmailDomains.length > 0" class="email-domain-list">
                        <div v-for="(registeredEmailDomain, index) in registeredEmailDomains" :key="index">
                          <p @click="margeUserSearchQuery(registeredEmailDomain)" class="mb-0 email-domain-list-item">{{ registeredEmailDomain }}</p>
                          <p ></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12 mt-2 mt-lg-0">
                      <button :disabled="userSearchQuery === ''" @click="searchUser" type="button" class="btn btn-block btn-primary">Submit</button>
                    </div>
                  </div>
                  <div v-if="existingRegisteredTechnician.id" class="row center-div mx-auto">

                    <div class="col-12 mt-1">
                      <hr>
                      <h5 class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Technician Already Exist!</span>
                      </h5>
                      <p class="col-12 d-md-flex justify-md-content-start align-items-center">
                        <strong>Technician Info:</strong>
                        <span>&nbsp;{{ existingRegisteredTechnicianDetails }}&nbsp;&nbsp;</span>
                        <router-link v-if="existingRegisteredTechnician.id"
                                     :to="can('manager-view') ? {name: 'appTechnicianView', params: {id: existingRegisteredTechnician.id }} : '#'"
                                     :title="can('manager-view') ? 'View Manager' : 'View Forbidden'">
                          <span class="glow d-flex align-items-center"><i class='bx bx-show'></i>&nbsp;View</span>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <!-- left side numbering steps of the process start. -->
                  <div class="col-lg-2 step-divider" >
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 1 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">1</div>
                      <div class="ml-1" :class="tabStep >= 1 ? 'text-primary' : ''">User Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 1 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 2 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">2</div>
                      <div class="ml-1" :class="tabStep >= 2 ? 'text-primary' : ''">Address Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 2 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 3 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">3</div>
                      <div class="ml-1" :class="tabStep >= 3 ? 'text-primary' : ''">Employee Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 3 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 4 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">4</div>
                      <div class="ml-1" :class="tabStep >= 4 ? 'text-primary' : ''">Bank Account Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 4 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 5 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">5</div>
                      <div class="ml-1" :class="tabStep >= 5 ? 'text-primary' : ''">Team Lead Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 5 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                    </div>

                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 6 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">6</div>
                      <div class="ml-1" :class="tabStep >= 6 ? 'text-primary' : ''">Technician Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 6 ? 'inner-line-primary-border' : 'inner-line-secondary-border'" class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 7 ? 'active-step' : 'inactive-step'" class="step-number d-flex justify-content-center align-items-center flex-shrink-0">7</div>
                      <div class="ml-1" :class="tabStep >= 7 ? 'text-primary' : ''">Preview Details</div>
                    </div>
                  </div>
                  <!-- left side numbering steps of the process end. -->
                  <!-- right side response steps of the process starts. -->
                  <div class="col-lg-10 mt-3 mt-lg-0">
                    <div class="row" v-if="tabStep===1">
                      <div class="col-12">
                        <h5>User Information</h5>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>First name *</label>
                          <input v-model="postUserData.first_name" type="text" class="form-control " placeholder="Enter first name">
                          <div class="text-danger" v-if="errors.user.firstName">{{ errors.user.firstName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Last name *</label>
                          <input v-model="postUserData.last_name" type="text" class="form-control " placeholder="Enter last name">
                          <div class="text-danger" v-if="errors.user.lastName">{{ errors.user.lastName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Phone Number *</label>
                          <input v-model="postUserData.phone_number" :readonly="userPhoneFilled" max="10" type="tel" class="form-control " placeholder="ie. 0412345678" aria-describedby="userAlreadyExistWarning">
                          <small v-if="userFound" id="userAlreadyExistWarning" class="form-text text-warning d-flex flex-row"><strong><i class='bx bx-info-circle'></i></strong>&nbsp;Previously registered users! Any changes will update this user's data.</small>
                          <div class="text-danger" v-if="errors.user.phoneNumber">{{ errors.user.phoneNumber }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Email Address *</label>
                          <input v-model="postUserData.email" :readonly="userEmailFilled" type="email" class="form-control" placeholder="ie. example@example.com.au" >
                          <div class="text-danger" v-if="errors.user.email">{{ errors.user.email }}</div>
                          <div class="text-danger" v-if="errors.address.userId">{{ errors.address.userId }}</div>
                          <div class="text-danger" v-if="errors.employee.userId">{{ errors.employee.userId }}</div>
                          <div class="text-danger" v-if="errors.technician.userId">{{ errors.technician.userId }}</div>
                          <div class="text-danger" v-if="errors.technician.employeeId">{{ errors.technician.employeeId }}</div>
                          <div class="text-danger" v-if="errors.bankAccount.userId">{{ errors.bankAccount.userId }}</div>
                          <div class="text-danger" v-if="errors.teamLead.userId">{{ errors.teamLead.userId }}</div>
                          <div class="text-danger" v-if="errors.onSiteTechnician.userId">{{ errors.onSiteTechnician.userId }}</div>
                          <div class="text-danger" v-if="errors.remoteTechnician.userId">{{ errors.remoteTechnician.userId }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Date of Birth</label>
                          <div class="d-flex align-items-center date-picker-wrapper">
                            <div class="d-flex align-items-center">
                              <i class='bx bx-calendar'></i>
                            </div>
                            <div class="w-100 pl-1 ">
                              <div class="w-100 ">
                                <DatePicker v-model="postUserData.date_of_birth"
                                            :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                            :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                                  </template>
                                </DatePicker>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.user.dateOfBirth">{{ errors.user.dateOfBirth }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Role</label>
                          <VueMultiselect v-model="selectedRole" class="" :options="roleList" :close-on-select="true" placeholder="Select a role" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                          <div class="text-danger" v-if="errors.user.roleId">{{ errors.user.roleId }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button @click="jumpToStart" class="btn btn-light-primary">Prev</button>
                          <button @click="createSingleUser" :disabled="!isPostUserAndPostTechnicianTypeDataFilled" class="btn btn-light-primary ml-1">Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep===2">
                      <div class="col-12">
                        <h5>Address Information</h5>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Address</label>
                          <small v-if="!addressInformationSelectDiv && hasUserAddresses" @click="addressInformationSelectDiv = !addressInformationSelectDiv" class="text-primary">&nbsp;(Click to select address from existing one)</small>
                          <small v-if="addressInformationSelectDiv" @click="addressInformationSelectDiv = !addressInformationSelectDiv" class="text-primary">&nbsp;(Click to create a new address)</small>
                          <VueMultiselect v-if="addressInformationSelectDiv" v-model="selectedAddress" :options="addressList" :close-on-select="true" placeholder="Select Address" label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                          <vue-google-autocomplete v-else :enable-geolocation="true" ref="address" :country="['au']" id="map" classname="form-control" placeholder="Please type full address." v-on:placechanged="googleMapApiResponseData"/>
                          <div class="text-danger" v-if="errors.employee.addressId">{{ errors.employee.addressId }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Please modify the following address fields, if there anything wrong!</span>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12">
                        <div class="form-group">
                          <label>Street Address *</label>
                          <input v-model="postAddressData.street" type="text" class="form-control" placeholder="Enter street address" />
                          <div class="text-danger" v-if="errors.address.street">{{ errors.address.street }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6 ">
                        <div class="form-group">
                          <label>Suburbs/ Territory *</label>
                          <input v-model="postAddressData.suburb" type="text" class="form-control" placeholder="Enter suburbs/ territory" />
                          <div class="text-danger" v-if="errors.address.suburb">{{ errors.address.suburb }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>State *</label>
                          <VueMultiselect v-model="selectedState" :allowEmpty="false" :options="stateList" :close-on-select="true" placeholder="Select state" label="name" track-by="value" :show-labels="false" />
                          <div class="text-danger" v-if="errors.address.state">{{ errors.address.state }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Post Code *</label>
                          <input v-model="postAddressData.post_code" type="text" class="form-control" placeholder="Enter code" required />
                          <div class="text-danger" v-if="errors.address.postCode">{{ errors.address.postCode }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Country *</label>
                          <input v-model="postAddressData.country" type="text" class="form-control" readonly required />
                          <div class="text-danger" v-if="errors.address.country">{{ errors.address.country }}</div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button v-if="addressInformationSelectDiv" class="btn btn-light-primary ml-1" :disabled="!selectedAddress.value" @click="tabNext">Next</button>
                          <button v-else class="btn btn-light-primary ml-1" :disabled="!hasAddressData" @click="createSingleAddress">Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep ===3">
                      <div class="col-12"><h5>Technician/Employee Type & Salary Information</h5></div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Technician/Employee Type</label>
                          <VueMultiselect v-model="selectedEmployeeType" class="" :options="employeeTypeList" :close-on-select="true" placeholder="Select the employee type" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                          <div class="text-danger" v-if="errors.employee.type">{{ errors.employee.type }}</div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Salary Type *</label>
                          <input readonly type="text" :value="postEmployeeData.salary_type === 0 ? 'Fixed' : 'Hourly'" class="form-control">
                          <div class="text-danger" v-if="errors.employee.salaryType">{{ errors.employee.salaryType }}</div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>{{ `${salaryAmountType} *`}}</label>
                          <input v-model="employeeSalaryAmount" min="0.00" step="0.001" type="number" class="form-control">
                          <div class="text-danger" v-if="errors.employee.salaryAmount">{{ errors.employee.salaryAmount }}</div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12"><h5>ID Information</h5></div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Designation *</label>
                          <input v-model="postEmployeeData.designation" type="text" class="form-control " placeholder="Enter designation">
                          <div class="text-danger" v-if="errors.employee.designation">{{ errors.employee.designation }}</div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>ID Number</label>
                          <input v-model="postEmployeeData.id_number" type="text" class="form-control " placeholder="Enter a unique ID">
                          <div class="text-danger" v-if="errors.employee.idNumber">{{ errors.employee.idNumber }}</div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType && needAbn" class="col-12"><h5>ABN Information</h5></div>
                      <div v-if="hasEmployeeType && needAbn" class="col-12">
                        <div class="form-group">
                          <label>ABN Information *</label>
                          <input v-model="postEmployeeData.abn" type="text" class="form-control " placeholder="Enter ABN">
                          <div class="text-danger" v-if="errors.employee.abn">{{ errors.employee.abn }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" :disabled="!isPostEmployeeDataFilled" @click="createSingleEmployee()">Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 4">
                      <div class="col-12"><h5>Bank Account Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Bank Name</label>
                          <input v-model="postBankAccountData.bank_name" type="text" class="form-control " placeholder="Enter bank name.">
                          <div class="text-danger" v-if="errors.bankAccount.bankName">{{ errors.bankAccount.bankName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Account Name</label>
                          <input v-model="postBankAccountData.account_name" type="text" class="form-control " placeholder="Enter account name.">
                          <div class="text-danger" v-if="errors.bankAccount.accountName">{{ errors.bankAccount.accountName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Account Number</label>
                          <input v-model="postBankAccountData.account_number" type="text" class="form-control " placeholder="Enter account number.">
                          <div class="text-danger" v-if="errors.bankAccount.accountNumber">{{ errors.bankAccount.accountNumber }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>BSB</label>
                          <input v-model="postBankAccountData.bsb" type="text" class="form-control " placeholder="Enter BSB.">
                          <div class="text-danger" v-if="errors.bankAccount.bsb">{{ errors.bankAccount.bsb }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" :disabled="!isPostBankAccountDataFilled" @click="existingRegisteredBankAccountDataHasChanged? createSingleBankAccount() : tabNext()">Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 5">
                      <div class="col-12"><h5>Team Lead & work for Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label>Team Lead Status</label>
                            <div class="" style="margin-top: .5rem">
                              <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                <div>
                                  <input v-model="employeeTeamLeadStatus" type="checkbox" class="custom-control-input" id="employeeTeamLeadStatusCheckbox">
                                  <label class="custom-control-label mr-1" for="employeeTeamLeadStatusCheckbox"></label>
                                </div>
                                <span class="font-medium-1">{{employeeTeamLeadStatus ? "Active" : "Inactive"}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.teamLead.status">{{ errors.teamLead.status }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label>Work For</label>
                            <VueMultiselect v-model="selectedSource"
                                            :options="sourceList"
                                            :close-on-select="true"
                                            :multiple="true"
                                            :show-labels="false"
                                            placeholder="Select referral"
                                            label="name"
                                            track-by="value"/>
                            <div class="text-danger" v-if="errors.technician.productCommissionType">{{ errors.technician.productCommissionType }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" @click="existingTeamLeadDataHasChanged ? createSingleTeamLead() : tabNext()">Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 6">
                      <div v-if="enableForProductCommission" class="col-12"><h5>Product Commission Information</h5></div>
                      <div v-if="enableForProductCommission" class="col-12" :class="enableForProductCommission && hasTechnicianProductCommissionType ? 'col-md-6' : ''">
                        <div class="form-group">
                          <label>Order/Product Profit Commission Type *</label>
                          <VueMultiselect v-model="selectedProductCommissionType" class="" :options="productCommissionTypeList" :close-on-select="true" placeholder="Select order/product profit commission type" label="name" track-by="value" :show-labels="false" />
                          <div class="text-danger" v-if="errors.technician.productCommissionType">{{ errors.technician.productCommissionType }}</div>
                        </div>
                      </div>
                      <div v-if="enableForProductCommission && hasTechnicianProductCommissionType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Order/Product Profit Commission Rate *</label>
                          <input v-model="postTechnicianData.product_commission_rate" type="number" min="0.00" step="0.001" class="form-control " placeholder="Enter order/product profit commission rate">
                          <div class="text-danger" v-if="errors.technician.productCommissionRate">{{ errors.technician.productCommissionRate }}</div>
                        </div>
                      </div>
                      <div class="col-12"><h5>Technician Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Time Interval (HH:MM) *</label>
                          <input v-model="postTechnicianData.interval_time" type="text"  class="form-control " placeholder="Enter interval time.">
                          <div class="text-danger" v-if="errors.technician.intervalTime">{{ errors.technician.intervalTime }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Team Lead</label>
                          <VueMultiselect :allow-empty="false" v-model="selectedTeamLead" class="" :options="teamLeadList" :close-on-select="true" placeholder="Select a team lead" label="name" track-by="value" :show-labels="false" />
                          <div class="text-danger" v-if="errors.technician.teamLeadId">{{ errors.technician.teamLeadId }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-12">
                        <div class="form-group">
                          <label>Skills</label>
                          <QuillEditor v-model:content="postTechnicianData.skills" contentType="html" toolbar="full" theme="snow" />
                          <div class="text-danger" v-if="errors.technician.skills">{{ errors.technician.skills }}</div>
                        </div>
                      </div>
                      <div class="col-12"><h5>Other Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label>On-Site Technician Status</label>
                            <div class="" style="margin-top: .5rem">
                              <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                <div>
                                  <input v-model="onSiteTechnicianStatus" type="checkbox" class="custom-control-input" checked id="onSiteTechnicianStatusCheckbox">
                                  <label class="custom-control-label mr-1" for="onSiteTechnicianStatusCheckbox"></label>
                                </div>
                                <span class="font-medium-1">{{onSiteTechnicianStatus ? "Active" : "Inactive"}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.onSiteTechnician.status">{{ errors.onSiteTechnician.status }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label>Remote Technician Status</label>
                            <div class="" style="margin-top: .5rem">
                              <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                <div>
                                  <input v-model="remoteTechnicianStatus" type="checkbox" class="custom-control-input" checked id="remoteTechnicianStatusCheckbox">
                                  <label class="custom-control-label mr-1" for="remoteTechnicianStatusCheckbox"></label>
                                </div>
                                <span class="font-medium-1">{{remoteTechnicianStatus ? "Active" : "Inactive"}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.remoteTechnician.status">{{ errors.remoteTechnician.status }}</div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" :disabled="!isPostTechnicianDataFilled" @click="tabNext">Next</button>
                        </div>
                      </div>
                    </div>


                    <div class="row" v-if="tabStep ===7">
                      <div class="col-12"><h3>Preview Details</h3></div>
                      <div class="col-12">
                        <table class="table table-responsive table-borderless table-transparent">
                          <tbody>
                          <tr><td class="bold">First Name</td><td>:&nbsp;{{ postUserData.first_name }}</td></tr>
                          <tr><td class="bold">Last Name</td><td>:&nbsp;{{ postUserData.last_name }}</td></tr>
                          <tr><td class="bold">Phone Number</td><td :class="postUserData.phone_number? '': 'text-danger'">:&nbsp;{{ postUserData.phone_number ? postUserData.phone_number : "Not Provided"}}</td></tr>
                          <tr><td class="bold">Email</td><td :class="postUserData.email? '': 'text-danger'">:&nbsp;{{ postUserData.email ? postUserData.email : "Not Provided" }}</td></tr>
                          <tr><td class="bold">Date of Birth</td><td :class="postUserData.date_of_birth? '': 'text-danger'">:&nbsp;{{ postUserData.date_of_birth ? postUserData.date_of_birth : "Not Provided" }}</td></tr>
                          <tr><td class="bold">Technician Role</td><td>:&nbsp;{{ selectedRole.name }}</td></tr>
                          <tr><td class="bold">Address</td><td>:&nbsp;{{ addressInformationSelectDiv ?  selectedAddress.name : filledAddress }}</td></tr>
                          <tr><td class="bold">Designation</td><td>:&nbsp;{{ postEmployeeData.designation }}</td></tr>
                          <tr><td class="bold">ID Number</td><td>:&nbsp;{{ postEmployeeData.id_number }}</td></tr>
                          <tr><td class="bold">Employee Type</td><td>:&nbsp;{{ selectedEmployeeType.name ?? selectedEmployeeType.name }}</td></tr>
                          <tr><td class="bold">Salary Type</td><td>:&nbsp;{{ salaryType }}</td></tr>
                          <tr><td class="bold">{{ salaryAmountType }}</td><td>:&nbsp;{{ employeeSalaryAmount }}</td></tr>
                          <tr v-if="needAbn"><td class="bold">ABN</td><td>:&nbsp;{{ postEmployeeData.abn }}</td></tr>
                          <tr><td class="bold">Employee's TeamLead</td><td>:&nbsp;{{ selectedTeamLead.name }}</td></tr>
                          <tr><td class="bold">Bank Name</td><td>:&nbsp;{{ postBankAccountData.bank_name }}</td></tr>
                          <tr><td class="bold">Bank Account Name</td><td>:&nbsp;{{ postBankAccountData.account_name }}</td></tr>
                          <tr><td class="bold">Bank Account Number</td><td>:&nbsp;{{ postBankAccountData.account_number }}</td></tr>
                          <tr><td class="bold">Bank Account BSB</td><td>:&nbsp;{{ postBankAccountData.bsb }}</td></tr>
                          <tr v-if="enableForProductCommission"><td class="bold">Order/Product Profit Commission Type</td><td>:&nbsp;{{ selectedProductCommissionType.name }}</td></tr>
                          <tr v-if="enableForProductCommission"><td class="bold">Order/Product Profit Commission Rate</td><td>:&nbsp;{{ postTechnicianData.product_commission_rate }}</td></tr>
                          <tr><td class="bold">Interval Time</td><td>:&nbsp;{{ postTechnicianData.interval_time }}</td></tr>
                          <tr><td class="bold">Skills :</td><td v-html="postTechnicianData.skills"></td></tr>
                          <tr><td class="bold">Team Lead Status</td><td>:&nbsp;{{ employeeTeamLeadStatus ? "Active" : "Inactive" }}</td></tr>
                          <tr><td class="bold">Work For</td><td>:&nbsp;{{ selectedSourceCode }}</td></tr>
                          <tr><td class="bold">On-site Technician Status</td><td>:&nbsp;{{ onSiteTechnicianStatus ? "Active" : "Inactive" }}</td></tr>
                          <tr><td class="bold">Remote Technician Status</td><td>:&nbsp;{{ remoteTechnicianStatus ? "Active" : "Inactive" }}</td></tr>
                          </tbody>
                        </table>
                        <div class="text-danger" v-if="errors.technician.status">{{ errors.technician.status }}</div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" @click="createSingleTechnician()">Create Technician</button>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- right side response steps of the process end. -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import {DatePicker} from 'v-calendar';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueMultiselect from 'vue-multiselect';
import {
  QuillEditor
} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "TechnicianCreate",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['routeName'],
  components: {
    AppLayout,
    DatePicker,
    VueGoogleAutocomplete,
    VueMultiselect,
    QuillEditor,
  },

  data() {
    return {
      selectedSource:[],
      selectedSourceCode: '',
      enableTechnicianCreate: true,
      userSearchQuery: '',
      userSearchEmailDomainPartQuery: '',
      userSearchStatus: false,
      userFound: false,
      userEmailFilled: false,
      userPhoneFilled: false,
      tabStep: 1,
      userId: null,
      employeeId: null,
      existingRegisteredTechnician: {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },

      postUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      existingRegisteredUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      addressInformationSelectDiv : false,
      hasUserAddresses: false,

      selectedEmployeeType: {
        'value': '',
        'name': 'Any',
      },

      selectedAddress: {
        value: '',
        name: 'None'
      },

      postAddressData: {
        user_id: '',
        street: '',
        suburb: '',
        state: '',
        post_code: '',
        country: '',
      },

      selectedState: {
        value: '',
        name: 'None'
      },

      postEmployeeData: {
        address_id: '',
        user_id: '',
        type: '',
        abn: '',
        id_number: '',
        designation: '',
        salary_type: 0,
        salary_amount: 0.00,
      },

      existingRegisteredEmployeeData: {
        address_id: '',
        user_id: '',
        type: '',
        abn: '',
        id_number: '',
        designation: '',
        salary_type: '',
        salary_amount: '',
      },

      postTechnicianData: {
        user_id: '',
        employee_id: '',
        team_lead_id: null,
        product_commission_type: 0,
        product_commission_rate: 0.00,
        interval_time: '01:00',
        skills: null,
        schedule: null,
        status: 1,
      },

      employeeTypeList: [
        {
          value: 0,
          name: "Permanent Full Time",
        },
        {
          value: 1,
          name: "Permanent Part Time",
        },
        {
          value: 2,
          name: "Contractual",
        },
        {
          value: 3,
          name: "Partner",
        },
      ],

      salaryTypes: [
        {
          value: 0,
          name: "Fixed",
        },
        {
          value: 1,
          name: "Hourly",
        },
      ],

      postBankAccountData: {
        user_id: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bsb: '',

      },

      postTeamLeadData: {
        user_id: '',
        employee_id: '',
        status: 0,
      },

      postOnSiteTechnicianData: {
        user_id: '',
        employee_id: '',
        technician_id: '',
        status: 1,
      },

      postRemoteTechnicianData: {
        user_id: '',
        employee_id: '',
        technician_id: '',
        status: 1,
      },

      postFranchiseeTechnicianData: {
        franchisee_id: '',
        technician_id: '',
      },

      employeeSalaryAmount: 0.00,

      employeeTeamLeadStatus: false,
      existingEmployeeTeamLeadStatus: false,

      existingRegisteredBankAccountData: {
        user_id: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bsb: '',
      },

      productStockShow: true,

      onSiteTechnicianStatus: true,

      remoteTechnicianStatus: true,

      selectedRole: {
        value: '',
        name: 'None'
      },

      productCommissionTypeList: [{
        value: 0,
        name: 'None',
      },
        {
          value: 1,
          name: 'Hardware',
        },
        {
          value: 2,
          name: 'Software',
        },
        {
          value: 3,
          name: 'Both',
        },
      ],

      selectedProductCommissionType: {
        value: 0,
        name: 'None',
      },

      selectedTeamLead: {
        value: '',
        name: 'No One'
      },


      errors: {
        existingRegisteredTechnician: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          dateOfBirth: '',
        },
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        employee: {
          addressId: '',
          userId: '',
          type: '',
          abn: '',
          idNumber: '',
          designation: '',
          salaryType: '',
          salaryAmount: '',
          status: '',
        },
        technician: {
          userId: '',
          employeeId: '',
          teamLeadId: '',
          productCommissionType: '',
          productCommissionRate: '',
          interval_time: '',
          skills: '',
          schedule: '',
          status: '',
        },
        bankAccount: {
          userId: '',
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsb: '',
        },
        teamLead: {
          userId: '',
          employeeId: '',
          status: '',
        },
        onSiteTechnician: {
          userId: '',
          employeeId: '',
          technicianId: '',
          status: '',
        },
        remoteTechnician: {
          userId: '',
          employeeId: '',
          technicianId: '',
          status: '',
        },
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },

      getRolesParams: {
        type: 2 // technician type
      },

      getUsersParams: {
        email_phone_where_has_user_additional_infos_query: '',
        with_relation: ['role', 'addresses', 'bankAccount', 'technician', 'employee', 'employee.address', 'employee.user', 'teamLead'],
      },
      getTeamLeadsParams: {
        status: 1,
        with_relation: ['user'],
      },
      getFranchiseeParams: {
        with_relation: ["franchiseeTechnicians"],
      },
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      users: 'appUsers/users',
      registeredEmailDomains: 'appUsers/registeredEmailDomains',
      authFranchisee: "appAuthLogin/authFranchisee",
      franchisee: 'appFranchisees/franchisee',
    }),

    sourceList() {
      let sources = this.$store.getters['appReferrals/referrals'].map(item => {
        return {
          value: item.id,
          name: item.code,
        };
      })
      return [{value: '', name: "Any"}, ...sources];
    },
    redirectRouteName() {
      return this.can('vacancy-view-any') ? 'appVacancyList' : 'appVacancyCreate';
    },

    registeredEmailDomains(){

      let registeredEmailDomains = this.$store.getters['appUsers/registeredEmailDomains'].email_domains;

      if (this.userSearchEmailDomainPartQuery !== '' && Array.isArray(registeredEmailDomains)) {
        return this.$store.getters['appUsers/registeredEmailDomains'].email_domains.filter((str)=>{
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      if(this.userSearchEmailDomainPartQuery !== '' && (typeof registeredEmailDomains) === 'object'){
        return Object.values(registeredEmailDomains).filter((str)=>{
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      return [];
    },

    existingRegisteredTechnicianDetails() {
      return `${this.existingRegisteredTechnician.firstName} ${this.existingRegisteredTechnician.lastName} (${this.existingRegisteredTechnician.email} | ${this.existingRegisteredTechnician.phoneNumber})`;
    },

    isPostUserAndPostTechnicianTypeDataFilled() {
      return !!(this.postUserData.first_name && this.postUserData.last_name && (this.postUserData.email || this.postUserData.phone_number) && this.postUserData.role_id !== '');
    },

    existingRegisteredUserDataHasChanged() {
      return this.postUserData.first_name !== this.existingRegisteredUserData.first_name ||
          this.postUserData.last_name !== this.existingRegisteredUserData.last_name ||
          this.postUserData.email !== this.existingRegisteredUserData.email ||
          this.postUserData.phone_number !== this.existingRegisteredUserData.phone_number ||
          this.postUserData.date_of_birth !== this.existingRegisteredUserData.date_of_birth;
    },

    existingRegisteredEmployeeDataHasChanged() {
      return this.postEmployeeData.address_id !== this.existingRegisteredEmployeeData.address_id ||
          this.postEmployeeData.user_id !== this.existingRegisteredEmployeeData.user_id ||
          this.postEmployeeData.type !== this.existingRegisteredEmployeeData.type ||
          this.postEmployeeData.abn !== this.existingRegisteredEmployeeData.abn ||
          this.postEmployeeData.id_number !== this.existingRegisteredEmployeeData.id_number ||
          this.postEmployeeData.designation !== this.existingRegisteredEmployeeData.designation ||
          this.postEmployeeData.salary_type !== this.existingRegisteredEmployeeData.salary_type ||
          this.postEmployeeData.salary_amount !== this.existingRegisteredEmployeeData.salary_amount;
    },

    roleList() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName
        };
      });

      return [{
        value: '',
        name: 'None'
      }, ...activeRoles];
    },

    addressList() {
      let userAddresses = this.users && this.users[0] && this.users[0].addresses ?
          this.users[0].addresses.map((address) => {
            let addressId = address.id;
            let addressStreet = address.street;
            let addressSuburb = address.suburb;
            let addressState = address.state.toUpperCase();
            let addressPostCode = address.post_code;
            let addressCountry = address.country;

            return {
              value: addressId,
              name: `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`
            };
          })  : [];

      return [
        {
          value: '',
          name: 'None'
        },
        ...userAddresses
      ];
    },

    stateList() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },

    hasAddressData() {
      return !!(this.postAddressData.street || this.postAddressData.suburb || this.postAddressData.state || this.postAddressData.post_code);
    },

    isPostEmployeeDataFilled() {
      return !!(this.postEmployeeData.type !== '' && this.postEmployeeData.designation && (this.postEmployeeData.salary_type === 0 || this.postEmployeeData.salary_type === 1) && this.postEmployeeData.salary_amount >= 0);
    },

    isPostBankAccountDataFilled() {
      return !!(this.postBankAccountData.bank_name && this.postBankAccountData.account_name && this.postBankAccountData.account_number && this.postBankAccountData.bsb);
    },

    filledAddress() {
      let addressStreet = this.postAddressData.street;
      let addressSuburb = this.postAddressData.suburb;
      let addressState = this.postAddressData.state.toUpperCase();
      let addressPostCode = this.postAddressData.post_code;
      let addressCountry = this.postAddressData.country;

      return `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    hasEmployeeType() {
      return this.postEmployeeData.type !== '';
    },

    salaryType() {
      if (this.postEmployeeData.type === 0 || this.postEmployeeData.type === 1) {
        return 'Fixed';
      } else {
        return 'Hourly';
      }
    },

    salaryAmountType() {
      if (this.postEmployeeData.type === 0 || this.postEmployeeData.type === 1) {
        return 'Salary Amount';
      } else {
        return 'Payment Rate (Hourly)';
      }
    },

    needAbn() {
      return this.postEmployeeData.type === 2 || this.postEmployeeData.type === 3;
    },

    existingTeamLeadDataHasChanged() {
      return this.employeeTeamLeadStatus !== this.existingEmployeeTeamLeadStatus;
    },

    existingRegisteredBankAccountDataHasChanged() {
      return this.postBankAccountData.user_id !== this.existingRegisteredBankAccountData.user_id ||
          this.postBankAccountData.bank_name !== this.existingRegisteredBankAccountData.bank_name ||
          this.postBankAccountData.account_name !== this.existingRegisteredBankAccountData.account_name ||
          this.postBankAccountData.account_number !== this.existingRegisteredBankAccountData.account_number ||
          this.postBankAccountData.bsb !== this.existingRegisteredBankAccountData.bsb;
    },

    enableForProductCommission() {
      return this.postEmployeeData.type === 2 || this.postEmployeeData.type === 3;
    },

    hasTechnicianProductCommissionType() {
      return this.selectedProductCommissionType.value !== 0;
    },

    isPostTechnicianDataFilled() {
      return this.postTechnicianData.interval_time !== '';
    },

    teamLeadList() {
      let activeTeamLeads = this.$store.getters['appTeamLeads/teamLeads'].map((teamLeadObj) => {
        let teamLeadId = teamLeadObj.id;

        let firstName = teamLeadObj.user && teamLeadObj.user.first_name ? teamLeadObj.user.first_name : '';
        let lastName = teamLeadObj.user && teamLeadObj.user.last_name ? teamLeadObj.user.last_name : '';
        let fullName = `${firstName} ${lastName}`;

        return {
          value: teamLeadId,
          name: fullName
        };
      });

      return [{
        value: '',
        name: `No One`
      }, ...activeTeamLeads];
    },
  },

  watch: {
    selectedSource(source){
      this.selectedSourceCode = source.filter(({value}) => value !== '').map(({name}) => name)?.join(',') ?? '';
    },
    userSearchQuery(userSearchQuery) {
      this.userSearchEmailDomainPartQuery = userSearchQuery.includes('@') ? userSearchQuery.substring(userSearchQuery.lastIndexOf('@') +1) : '';
    },

    selectedRole(selectedRole) {
      this.postUserData.role_id = selectedRole.value;
    },

    selectedEmployeeType(selectedEmployeeType) {
      this.postEmployeeData.type = selectedEmployeeType.value;

      if (selectedEmployeeType.value === 0 || selectedEmployeeType.value === 1) {
        this.postEmployeeData.salary_type = 0; // fixed
        this.postEmployeeData.abn = '';
      } else {
        this.postEmployeeData.salary_type = 1; // hourly
        this.postEmployeeData.abn = this.existingRegisteredEmployeeData.abn;
      }
    },

    selectedAddress(selectedAddress) {
      this.postEmployeeData.address_id = selectedAddress.value ?? null;
    },

    selectedState(selectedState) {
      this.postAddressData.state = selectedState.value;
    },

    employeeSalaryAmount(employeeSalaryAmount) {
      this.postEmployeeData.salary_amount = employeeSalaryAmount*100;
    },

    employeeTeamLeadStatus() {
      this.postTeamLeadData.status = this.employeeTeamLeadStatus ? 1 : 0;
    },

    selectedProductCommissionType() {
      this.postTechnicianData.product_commission_type = this.selectedProductCommissionType.value;

      if (this.selectedProductCommissionType.value === 0) {
        this.postTechnicianData.product_commission_rate = 0.00;
      }
    },

    onSiteTechnicianStatus() {
      this.postOnSiteTechnicianData.status = this.onSiteTechnicianStatus ? 1 : 0;
    },

    remoteTechnicianStatus() {
      this.postRemoteTechnicianData.status = this.remoteTechnicianStatus ? 1 : 0;
    },

  },

  methods: {
    ...mapActions({
      resetUser: 'appUsers/resetUser',
      resetUsers: 'appUsers/resetUsers',
      resetRoles: 'appRoles/resetRoles',
      resetAddress: 'appAddresses/resetAddress',
      resetAddresses: 'appAddresses/resetAddresses',
      resetEmployee: 'appEmployees/resetEmployee',
      resetBankAccount: 'appBankAccounts/resetBankAccount',
      resetTechnician: 'appTechnicians/resetTechnician',


      postUser: 'appUsers/postUser',
      putUser: 'appUsers/putUser',
      postAddress: 'appAddresses/postAddress',
      postEmployee: 'appEmployees/postEmployee',
      putEmployee: 'appEmployees/putEmployee',
      postBankAccount: 'appBankAccounts/postBankAccount',
      postTeamLead: 'appTeamLeads/postTeamLead',
      postTechnician: 'appTechnicians/postTechnician',
      postOnSiteTechnician: 'appOnSiteTechnicians/postOnSiteTechnician',
      postRemoteTechnician: 'appRemoteTechnicians/postRemoteTechnician',
      postFranchiseeTechnician: 'appFranchiseeTechnicians/postFranchiseeTechnician',

      getSettings: 'appSettings/getSettings',
      getUsers: 'appUsers/getUsers',
      getRoles: 'appRoles/getRoles',
      getRegisteredEmailDomains: 'appUsers/getRegisteredEmailDomains',
      getTeamLeads: 'appTeamLeads/getTeamLeads',
      getFranchisee: "appFranchisees/getFranchisee",
      getReferral: "appReferrals/getReferrals",
    }),

    async margeUserSearchQuery(registeredEmailDomain) {
      let firstPart = this.userSearchQuery.substring(0, this.userSearchQuery.lastIndexOf('@'));
      this.userSearchQuery = `${firstPart}@${registeredEmailDomain}`;
      await this.searchUser();
      this.userSearchEmailDomainPartQuery = '';
    },

    async tabPrev() {
      this.tabStep--;
    },

    async tabNext() {
      this.tabStep++;
    },

    async tabJump(tabToJump) {
      this.tabStep = tabToJump;
    },

    async setExistingRegisteredTechnician(user, technicianId) {
      this.existingRegisteredTechnician = {
        id: technicianId,
        userId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
      };
    },

    async resetExistingRegisteredTechnician() {
      this.existingRegisteredTechnician = {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      };
    },

    async setExistingRegisteredTechnicianError() {
      this.errors.existingRegisteredTechnician = 'The user is already registered as a technician.';
    },
    async resetExistingRegisteredTechnicianError() {
      this.errors.existingRegisteredTechnician = '';
    },



    async setExistingRegisteredUserData(user) {
      this.existingRegisteredUserData.role_id = user.role && user.role.id ? user.role.id : '';
      this.existingRegisteredUserData.first_name = user.first_name ?? '';
      this.existingRegisteredUserData.last_name = user.last_name ?? '';
      this.existingRegisteredUserData.email = user.email ?? '';
      this.existingRegisteredUserData.phone_number = user.phone_number ?? '';
      this.existingRegisteredUserData.date_of_birth = user.date_of_birth ?? '';
    },

    async setPostUserData(user) {
      this.postUserData.role_id = user.role && user.role.id ? user.role.id : '';
      this.postUserData.first_name = user.first_name ?? '';
      this.postUserData.last_name = user.last_name ?? '';
      this.postUserData.email = user.email ?? '';
      this.postUserData.phone_number = user.phone_number ?? '';
      this.postUserData.date_of_birth = user.date_of_birth ?? '';
    },
    async resetPostUserData() {
      this.postUserData = {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      };
    },

    async resetSelectedRole() {
      this.selectedRole = {
        value: '',
        name: 'None'
      };
    },

    async resetSelectedAddress() {
      this.selectedAddress = {
        value: '',
        name: 'None'
      };
    },

    async setPostEmployeeData(employee) {
      this.postEmployeeData.address_id = employee.address && employee.address.id ? employee.address.id : '';
      this.postEmployeeData.user_id = employee.user && employee.user.id ? employee.user.id : '';
      this.postEmployeeData.type = employee.type ? this.employeeTypeList.find(type => type.name === employee.type).value : '';
      this.postEmployeeData.abn = employee.abn ?? '';
      this.postEmployeeData.id_number = employee.id_number ?? '';
      this.postEmployeeData.designation = employee.designation ?? '';
      this.postEmployeeData.salary_type = employee.salary_type ? this.salaryTypes.find(type => type.name === employee.salary_type).value : '';
      this.postEmployeeData.salary_amount = employee.salary_amount ?? '';
    },

    async setExistingRegisteredEmployeeData(employee) {
      this.existingRegisteredEmployeeData.address_id = employee.address && employee.address.id ? employee.address.id : '';
      this.existingRegisteredEmployeeData.user_id = employee.user && employee.user.id ? employee.user.id : '';
      this.existingRegisteredEmployeeData.type = employee.type ? this.employeeTypeList.find(type => type.name === employee.type).value : '';
      this.existingRegisteredEmployeeData.abn = employee.abn ?? '';
      this.existingRegisteredEmployeeData.id_number = employee.id_number ?? '';
      this.existingRegisteredEmployeeData.designation = employee.designation ?? '';
      this.existingRegisteredEmployeeData.salary_type = employee.salary_type ? this.salaryTypes.find(type => type.name === employee.salary_type).value : '';
      this.existingRegisteredEmployeeData.salary_amount = employee.salary_amount ?? '';

    },

    async setPostBankAccountData(bankAccount) {
      this.postBankAccountData.user_id = bankAccount.user && bankAccount.user.id ? bankAccount.user.id : this.userId;
      this.postBankAccountData.bank_name = bankAccount.bank_name ?? '';
      this.postBankAccountData.account_name = bankAccount.account_name ?? '';
      this.postBankAccountData.account_number = bankAccount.account_number ?? '';
      this.postBankAccountData.bsb = bankAccount.bsb ?? '';
    },

    async setExistingRegisteredBankAccountData(bankAccount) {
      this.existingRegisteredBankAccountData.user_id = bankAccount.user && bankAccount.user.id ? bankAccount.user.id : this.userId;
      this.existingRegisteredBankAccountData.bank_name = bankAccount.bank_name ?? '';
      this.existingRegisteredBankAccountData.account_name = bankAccount.account_name ?? '';
      this.existingRegisteredBankAccountData.account_number = bankAccount.account_number ?? '';
      this.existingRegisteredBankAccountData.bsb = bankAccount.bsb ?? '';
    },

    async resetPostEmployeeData() {
      this.postEmployeeData = {
        address_id: '',
        user_id: '',
        type: '',
        abn: '',
        id_number: '',
        designation: '',
        salary_type: 0,
        salary_amount: 0.00,
      };
    },

    async resetErrors() {
      this.errors = {
        existingRegisteredTechnician: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          dateOfBirth: '',
        },
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        employee: {
          addressId: '',
          userId: '',
          type: '',
          abn: '',
          idNumber: '',
          designation: '',
          salaryType: '',
          salaryAmount: '',
          status: 1,
        },
        technician: {
          userId: '',
          employeeId: '',
          teamLeadId: '',
          productCommissionType: '',
          productCommissionRate: '',
          interval_time: '',
          skills: '',
          schedule: '',
          status: '',
        },
        bankAccount: {
          userId: '',
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsb: '',
        },
        teamLead: {
          userId: '',
          employeeId: '',
          status: 0,
        },
        onSiteTechnician: {
          userId: '',
          employeeId: '',
          technicianId: '',
          status: '',
        },
        remoteTechnician: {
          userId: '',
          employeeId: '',
          technicianId: '',
          status: '',
        },
      }
    },

    async jumpToStart() {
      this.userSearchStatus = false;
      this.userFound = false;
      this.userEmailFilled = false;
      this.userPhoneFilled = false;
      this.tabStep = 1;
      this.userId = null;

      await this.resetUsers();
      await this.resetUser();
      await this.resetPostUserData();
      await this.resetSelectedRole();
      await this.resetSelectedAddress();
      await this.resetErrors();

      this.addressInformationSelectDiv = false;
      this.hasUserAddresses = false;

    },

    async validateEmailString(string) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
    },

    async searchUser() {
      if (!this.enableTechnicianCreate) {
        const toastObj = {
          message: 'You are reached out to your limit of technician creation.',
          type: 'error'
        };
        this.showToastMessage(toastObj);
        return;
      }

      this.loader(true);
      this.userSearchEmailDomainPartQuery = '';

      this.getUsersParams.email_phone_where_has_user_additional_infos_query = this.userSearchQuery;
      await this.getUsers(this.getUsersParams);

      if (this.users.length > 0) {

        let user = this.users[0];

        if (user && user.technician && user.technician.id) {
          await this.setExistingRegisteredTechnicianError();
          await this.setExistingRegisteredTechnician(user, user.technician.id);
          this.loader(false);
          return;
        }

        await this.resetExistingRegisteredTechnicianError()
        await this.resetExistingRegisteredTechnician();

        this.userFound = true;

        this.userId = user.id;

        await this.setPostUserData(user);
        await this.setExistingRegisteredUserData(user);

        if (user.role) {
          this.selectedRole.value = user.role.id;
          this.selectedRole.name = user.role.name;
        }

        if (user.employee) {
          this.employeeId = user.employee.id;
          await this.setPostEmployeeData(user.employee);
          await this.setExistingRegisteredEmployeeData(user.employee);
          let employeeType = this.employeeTypeList.find(type => type.name === user.employee.type);
          if (employeeType) {
            this.selectedEmployeeType.value = employeeType.value;
            this.selectedEmployeeType.name = employeeType.name;
          }

          this.employeeSalaryAmount = (user.employee.salary_amount/100).toFixed(2);

          this.postTeamLeadData.employee_id = this.employeeId;
          this.postOnSiteTechnicianData.employee_id = this.employeeId;
          this.postRemoteTechnicianData.employee_id = this.employeeId;
          this.postTechnicianData.employee_id = this.employeeId;
        }

        if (user.employee && user.employee.address) {
          let address = await this.addressList.find(address => address.value === user.employee.address.id);
          if (address) {
            this.selectedAddress.value = address.value;
            this.selectedAddress.name = address.name;
          }
        }

        if (user.bankAccount) {
          await this.setPostBankAccountData(user.bankAccount);
          await this.setExistingRegisteredBankAccountData(user.bankAccount);
        }

        if (user.teamLead) {
          this.postTeamLeadData.user_id = user.teamLead.user_id ?? this.userId;
          this.postTeamLeadData.employee_id = user.teamLead.employee_id ?? this.employeeId
          this.postTeamLeadData.status = user.teamLead.status === 'Active' ? 1 : 0;

          this.existingEmployeeTeamLeadStatus =  user.teamLead.status === 'Active';
          this.employeeTeamLeadStatus = user.teamLead.status === 'Active';
        }

        this.postAddressData.user_id = this.userId;
        this.postEmployeeData.user_id = this.userId;
        this.postBankAccountData.user_id = this.userId;
        this.postTeamLeadData.user_id = this.userId;
        this.postTechnicianData.user_id = this.userId;
        this.postOnSiteTechnicianData.user_id = this.userId;
        this.postRemoteTechnicianData.user_id = this.userId;
      }

      if (this.users.length <= 0) {
        if (await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.email = this.userSearchQuery;
          this.userEmailFilled = true;
        }

        if (! await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.phone_number = this.userSearchQuery;
          this.userPhoneFilled = true;
        }
      }

      if (this.addressList.length > 1) {
        this.addressInformationSelectDiv = true;
        this.hasUserAddresses = true;
      }

      this.userSearchStatus = true;

      this.loader(false);
    },

    async createSingleUser() {
      if (!this.userId) {
        this.loader(true);
        await this.postUser(this.postUserData).then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.userId = user.id;
            this.postAddressData.user_id = this.userId;
            this.postEmployeeData.user_id = this.userId;
            this.postTechnicianData.user_id = this.userId;
            this.postBankAccountData.user_id = this.userId;
            this.postTeamLeadData.user_id = this.userId;
            this.postOnSiteTechnicianData.user_id = this.userId;
            this.postRemoteTechnicianData.user_id = this.userId;
            await this.tabNext();
          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
          this.errors.user.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }
        });
        this.loader(false);
        return;
      }

      if (this.userId && this.existingRegisteredUserDataHasChanged) {
        this.loader(true);
        let dataObj = {
          id: this.userId,
          data: this.postUserData,
        };
        await this.putUser(dataObj).then(async (response) => {
          if (response.status === 200) {
            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.postAddressData.user_id = this.userId;
            this.postEmployeeData.user_id = this.userId;
            this.postTechnicianData.user_id = this.userId;
            this.postBankAccountData.user_id = this.userId;
            this.postTeamLeadData.user_id = this.userId;
            this.postOnSiteTechnicianData.user_id = this.userId;
            this.postRemoteTechnicianData.user_id = this.userId;
            await this.tabNext();
          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
          this.errors.user.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

          this.showToastMessage(response);
        });
        this.loader(false);
        return;
      }

      if (this.userId && !this.existingRegisteredUserDataHasChanged) {
        await this.tabNext();
      }
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {
      this.loader(true);
      let streetNumber = googleMapApiResponseData.street_number ?? '';
      let streetName = googleMapApiResponseData.route ?? '';
      let suburbs = googleMapApiResponseData.locality ?? '';
      let state = googleMapApiResponseData.administrative_area_level_1 ?? '';
      let postCode = googleMapApiResponseData.postal_code ?? '';
      let country = googleMapApiResponseData.country ?? '';

      this.postAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.postAddressData.suburb = suburbs;
      this.postAddressData.state = state.toLowerCase();
      this.postAddressData.post_code = postCode;
      this.postAddressData.country = country;

      if (state) {
        let addressStateIndex = this.stateList.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.stateList[addressStateIndex];

          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }
      this.loader(false);
    },

    async createSingleAddress() {
      this.loader(true);
      await this.postAddress(this.postAddressData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {

          let address = this.$store.getters['appAddresses/address'];
          this.postEmployeeData.address_id = address.id;

          let newAddress = {
            value: address.id,
            name: `${address.street}, ${address.suburb} ${address.state} ${address.post_code}, ${address.country}`
          };

          this.addressList.push(newAddress);
          this.hasUserAddresses = true;

          await this.tabNext();
        }

        this.errors.address.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.address.street = response.errors && response.errors.street ? response.errors.street[0] : '';
        this.errors.address.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
        this.errors.address.state = response.errors && response.errors.state ? response.errors.state[0] : '';
        this.errors.address.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
        this.errors.address.country = response.errors && response.errors.country ? response.errors.country[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.address.userId) {
          await this.tabJump(1);
        }

      });
      this.loader(false);
    },

    async createSingleEmployee() {
      if (!this.employeeId) {
        this.loader(true);
        await this.postEmployee(this.postEmployeeData).then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            let employee = this.$store.getters['appEmployees/employee']
            await this.setExistingRegisteredEmployeeData(employee);
            this.postTeamLeadData.employee_id = employee.id;
            this.postTechnicianData.employee_id = employee.id;
            this.postOnSiteTechnicianData.employee_id = employee.id;
            this.postRemoteTechnicianData.employee_id = employee.id;
            this.postTechnicianData.employee_id = employee.id;
            await this.tabNext();
          }

          this.errors.employee.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.errors.employee.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
          this.errors.employee.type = response.errors && response.errors.type ? response.errors.type[0] : '';
          this.errors.employee.abn = response.errors && response.errors.abn ? response.errors.abn[0] : '';
          this.errors.employee.idNumber = response.errors && response.errors.id_number ? response.errors.id_number[0] : '';
          this.errors.employee.designation = response.errors && response.errors.designation ? response.errors.designation[0] : '';
          this.errors.employee.salaryType = response.errors && response.errors.salary_type ? response.errors.salary_type[0] : '';
          this.errors.employee.salaryAmount = response.errors && response.errors.salary_amount ? response.errors.salary_amount[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }

          if (this.errors.employee.addressId) {
            await this.tabJump(2);
            this.loader(false);
            return;
          }
          if (this.errors.employee.userId) {
            await this.tabJump(1);
            this.loader(false);
          }
        });
        this.loader(false);
        return;
      }

      if (this.employeeId && this.existingRegisteredEmployeeDataHasChanged) {
        this.loader(true);
        let dataObj = {
          id: this.employeeId,
          data: this.postEmployeeData,
        };
        await this.putEmployee(dataObj).then(async (response) => {
          if (response.status === 200) {
            let employee = this.$store.getters['appEmployees/employee'];
            await this.setExistingRegisteredEmployeeData(employee);

            this.postTechnicianData.employee_id = employee.id;
            this.postOnSiteTechnicianData.employee_id = employee.id;
            this.postRemoteTechnicianData.employee_id = employee.id;
            this.postTeamLeadData.employee_id = employee.id;

            await this.tabNext();
          }

          this.errors.employee.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.errors.employee.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
          this.errors.employee.type = response.errors && response.errors.type ? response.errors.type[0] : '';
          this.errors.employee.abn = response.errors && response.errors.abn ? response.errors.abn[0] : '';
          this.errors.employee.idNumber = response.errors && response.errors.id_number ? response.errors.id_number[0] : '';
          this.errors.employee.designation = response.errors && response.errors.designation ? response.errors.designation[0] : '';
          this.errors.employee.salaryType = response.errors && response.errors.salary_type ? response.errors.salary_type[0] : '';
          this.errors.employee.salaryAmount = response.errors && response.errors.salary_amount ? response.errors.salary_amount[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }

          if (this.errors.employee.addressId) {
            await this.tabJump(2);
            this.loader(false);
            return;
          }
          if (this.errors.employee.userId) {
            await this.tabJump(1);
            this.loader(false);
          }
        });
        this.loader(false);
        return;
      }

      if (this.employeeId && !this.existingRegisteredEmployeeDataHasChanged) {
        await this.tabNext();
      }
    },

    async createSingleBankAccount() {
      this.loader(true);
      await this.postBankAccount(this.postBankAccountData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          await this.tabNext();
        }

        this.errors.bankAccount.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.bankAccount.bank_account_id = response.errors && response.errors.bank_account_id ? response.errors.bank_account_id[0] : '';
        this.errors.bankAccount.account_name = response.errors && response.errors.account_name ? response.errors.account_name[0] : '';
        this.errors.bankAccount.account_number = response.errors && response.errors.account_number ? response.errors.account_number[0] : '';
        this.errors.bankAccount.bsb = response.errors && response.errors.bsb ? response.errors.bsb[0] : '';
        this.errors.bankAccount.account_type = response.errors && response.errors.account_type ? response.errors.account_type[0] : '';
        this.errors.bankAccount.account_type_id = response.errors && response.errors.account_type_id ? response.errors.account_type_id[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.bankAccount.userId) {
          await this.tabJump(1);
        }
      });
      this.loader(false);
    },

    async createSingleTeamLead() {
      this.loader(true);
      await this.postTeamLead(this.postTeamLeadData).then( async (response) => {
        if (response.status === 200 || response.status === 201) {
          await this.tabNext();
        }

        this.errors.teamLead.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.teamLead.employeeId = response.errors && response.errors.employee_id ? response.errors.employee_id[0] : '';
        this.errors.teamLead.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.teamLead.userId) {
          await this.tabJump(1);
        }
      });
      this.loader(false);
    },

    async createSingleOnsiteTechnician() {
      await this.postOnSiteTechnician(this.postOnSiteTechnicianData).then( async (response) => {

        this.errors.onSiteTechnician.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.onSiteTechnician.employeeId = response.errors && response.errors.employee_id ? response.errors.employee_id[0] : '';
        this.errors.onSiteTechnician.technicianId = response.errors && response.errors.technician_id ? response.errors.technician_id[0] : '';
        this.errors.onSiteTechnician.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

    async createSingleRemoteTechnician() {
      await this.postRemoteTechnician(this.postRemoteTechnicianData).then( async (response) => {

        this.errors.remoteTechnician.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.remoteTechnician.employeeId = response.errors && response.errors.employee_id ? response.errors.employee_id[0] : '';
        this.errors.remoteTechnician.technicianId = response.errors && response.errors.technician_id ? response.errors.technician_id[0] : '';
        this.errors.remoteTechnician.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });

    },

    async createSingleFranchiseeTechnician() {
      await this.postFranchiseeTechnician(this.postFranchiseeTechnicianData);
    },

    async createSingleTechnician() {
      this.loader(true);
      const sourceIds = this.selectedSource.filter(({value}) => value !== '').map(({value}) => value);
      this.postTechnicianData =  {...this.postTechnicianData, source_ids: sourceIds};
      await this.postTechnician(this.postTechnicianData).then(async (response) => {
        if (response.status === 201) {
          let technicianId = this.$store.getters['appTechnicians/technician'].id;

          this.postOnSiteTechnicianData.technician_id = technicianId;
          this.postRemoteTechnicianData.technician_id = technicianId;
          this.postFranchiseeTechnicianData.technician_id = technicianId;

          if (this.postRemoteTechnicianData.user_id && this.postRemoteTechnicianData.employee_id && this.postRemoteTechnicianData.technician_id && this.postRemoteTechnicianData.status) {
            await this.createSingleRemoteTechnician();
          }

          if (this.postOnSiteTechnicianData.user_id && this.postOnSiteTechnicianData.employee_id && this.postOnSiteTechnicianData.technician_id && this.postOnSiteTechnicianData.status) {
            await this.createSingleOnsiteTechnician();
          }

          if (this.postFranchiseeTechnicianData.franchisee_id && this.postFranchiseeTechnicianData.technician_id) {
            await this.createSingleFranchiseeTechnician();
          }

          const toastObj = {
            message: 'Technician created successfully.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          if(this.can('technician-update')) {
            await this.$router.push({
              name: 'appTechnicianScheduleEdit',
              params: {
                id: technicianId
              }
            });
          } else {
            await this.$router.push({
              name: this.redirectRouteName,
            });
          }



        }

        this.errors.technician.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.technician.employeeId = response.errors && response.errors.employee_id ? response.errors.employee_id[0] : '';
        this.errors.technician.productCommissionType = response.errors && response.errors.product_commission_type ? response.errors.product_commission_type[0] : '';
        this.errors.technician.productCommissionRate = response.errors && response.errors.product_commission_rate ? response.errors.product_commission_rate[0] : '';
        this.errors.technician.intervalTime = response.errors && response.errors.interval_time ? response.errors.interval_time[0] : '';
        this.errors.technician.skills = response.errors && response.errors.skills ? response.errors.skills[0] : '';
        this.errors.technician.schedule = response.errors && response.errors.schedule ? response.errors.schedule[0] : '';
        this.errors.technician.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.technician.userId || this.errors.technician.employeeId) {
          await this.tabJump(1);
          this.loader(false);
          return;
        }

        if (this.errors.technician.productCommissionType || this.errors.technician.productCommissionRate || this.errors.technician.intervalTime || this.errors.technician.skills || this.errors.technician.schedule) {
          await this.tabJump(6);
        }
      });
      this.loader(false);
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getRolesList() {
      await this.getRoles(this.getRolesParams);
    },

    async getTeamLeadList() {
      await this.getTeamLeads(this.getTeamLeadsParams);
    },

    async getSingleFranchisee() {
      let paramObj = {
        id: this.authFranchisee.id,
        params: this.getFranchiseeParams,
      };

      await this.getFranchisee(paramObj);
    },

    async getRegisterEmailDomainList() {
      await this.getRegisteredEmailDomains();
    },
  },

  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getRolesList();
    await this.getTeamLeadList();
    await this.getReferral();
    await this.getRegisterEmailDomainList();

    if (this.authFranchisee.id) {
      this.postFranchiseeTechnicianData.franchisee_id = this.authFranchisee.id;

      await this.getSingleFranchisee();
      let franchiseeTechnicianLimit = this.franchisee.technician_limit ? this.franchisee.technician_limit : 0;
      let TotalFranchiseeTechnician = this.franchisee.franchiseeTechnicians ? this.franchisee.franchiseeTechnicians.length : 0;
      if (TotalFranchiseeTechnician >= franchiseeTechnicianLimit) {
        this.enableTechnicianCreate = false;
      }
    }

    await this.loader(false);
  },

  async beforeMount() {
    await this.resetUser();
    await this.resetUsers();
    await this.resetRoles();
    await this.resetAddress();
    await this.resetAddresses();
    await this.resetTechnician();
  }
}
</script>

<style scoped>
.center-div{
  width: 85%;
}

.step-number {
  width: 38px;
  height: 38px;
  color: white;
  border-radius: 0.375rem
}

.step-line {
  width: 38px;
  height: 38px;

}

.inner-line {
  display: inline-block !important;
  /* border-left: 1px solid #5a8dee; */
  height: 100%;
}

.inner-line-primary-border {
  border-left: 1px solid #5a8dee;
}

.inner-line-secondary-border {
  border-left: 1px solid gray;
}

.active-step {
  background-color: #5a8dee;
}

.inactive-step {
  background-color: #90a5cc;
}
.step-divider{
  border-right: 1px solid #d4d8dd;
}

.email-domain-list {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 1rem;
  background-color: gray;
  max-height: 217px;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.5rem 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #d2ddec;
  border-right: 1px solid #d2ddec;
  border-bottom: 1px solid #d2ddec;
  z-index: 30;
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 20, 0.20);
  -moz-box-shadow: 0 4px 5px 0 rgba(0,0, 20,0.20);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 20, 0.20);
}
.email-domain-list-item {
  padding-left: 1rem;
  cursor: pointer;
  margin: 2px 0;
  color: black;
}
.email-domain-list-item:hover {
  background-color: #5a8dee;
  color: white;

}

@media only screen and (max-width: 600px) {
  .center-div{
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .step-divider{
    border-right: none;
    border-bottom:  1px solid #d4d8dd;
    padding-bottom: 27px;
  }
}
</style>